<template>
  <v-container v-if="clinic" fluid class="py-0">
    <heading>
      <h2>{{ $t("clinics-.details") }}</h2>
      <p>{{ $t("clinics-.details-sub-heading") }}</p>
      <template #actions>
        <v-btn
          color="primary"
          elevation="0"
          @click.prevent="$router.push({ name: 'clinic-new' })"
        >
          <v-img max-width="22px" :src="require(`@/assets/plus.svg`)" class="mr-2" />
          {{ $t("clinics-.new") }}
        </v-btn>
      </template>
    </heading>
    <split-content>
      <template #auxiliar>
        <v-row>
          <v-col>
            <v-text-field
              dense
              hide-details
              v-model="search"
              outlined
              :label="$t('search')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-list class="v-list-search-result">
              <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item v-for="(item, i) in clinics" :key="i">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </template>
      <v-container fluid>
        <v-row>
          <v-col>
            <h4 class="fg-black">{{ $t("clinic") }}</h4>
          </v-col>
          <v-col cols="6" class="svg-buttons small-buttons text-right">
            <v-btn
              small
              text
              class="fg-blue"
              @click="$router.push({ name: 'clinics-edit', params: { id: clinic.id } })"
            >
              <v-img max-width="22px" :src="require(`@/assets/edit-hover.svg`)" />
              {{ $t("edit") }}
            </v-btn>
            <remove-dialog :item="clinic" @removed="$router.push({ name: 'clinics' })">
              <template #activator="{ on, attrs }">
                <v-btn small text class="fg-red" v-bind="attrs" v-on="on">
                  <v-img max-width="22px" :src="require(`@/assets/delete-hover.svg`)" />
                  {{ $t("remove") }}
                </v-btn>
              </template>
            </remove-dialog>
          </v-col>
        </v-row>
        <v-row class="bottom-lined">
          <v-col cols="4">
            <attribute>
              <template #name>{{ $t("name") }}</template>
              {{ clinic.name }}
            </attribute>
          </v-col>
          <v-col cols="4">
            <attribute>
              <template #name>{{ $t("company") }}</template>
              {{ clinic.company }}
            </attribute>
          </v-col>
          <v-col cols="12">
            <attribute>
              <template #name>{{ $t("address") }}</template>
              {{ clinic.formatted_address }}
            </attribute>
          </v-col>
          <v-col cols="12">
            <attribute>
              <template #name>{{ $t("manager") }}</template>
              {{ clinic.manager_name }}
            </attribute>
          </v-col>
          <v-col cols="4">
            {{ clinic.manager_phone }}
          </v-col>
          <v-col cols="4">
            {{ clinic.manager_email }}
          </v-col>
          <v-col v-if="clinic.logo" cols="12">
            <attribute>
              <template #name>{{ $t("logo") }}</template>
              <v-img
                max-height="150"
                max-width="150"
                :src="`${apiPath}/clinics/${clinic.id}/logo`"
              />
            </attribute>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h4 class="fg-black">{{ $t("account") }}</h4>
          </v-col>
          <v-col cols="6" class="svg-buttons small-buttons text-right">
            <v-btn
              small
              text
              class="fg-blue"
              @click="$router.push({ name: 'clinics-edit', params: { id: clinic.id } })"
            >
              <v-img max-width="22px" :src="require(`@/assets/edit-hover.svg`)" />
              {{ $t("edit") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="bottom-lined">
          <v-col cols="12" sm="4">
            <attribute>
              <template #name>{{ $t("email") }}</template>
              {{ clinic.user.email }}
            </attribute>
          </v-col>
          <v-col cols="12" sm="4">
            <attribute>
              <template #name>{{ $t("password") }}</template>
              <change-password-dialog :item="clinic.user">
                <template #activator="{ on, attrs }">
                  <a v-bind="attrs" v-on="on">{{ $t("change-password") }}</a>
                </template>
              </change-password-dialog>
            </attribute>
          </v-col>
        </v-row>
        <v-row class="bottom-lined">
          <v-col>
            <h4 class="fg-black">{{ $t("registered-by") }}</h4>
          </v-col>
          <v-col cols="12">
            <attribute>
              <template #name>{{ $t("manager") }}</template>
              {{ clinic.created_by }}
            </attribute>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h4 class="fg-black">{{ $t("payments") }}</h4>
          </v-col>
        </v-row>
        <v-row class="bottom-lined">
          <v-col cols="12"> </v-col>
        </v-row>
      </v-container>
    </split-content>
  </v-container>
</template>

<script>
import Heading from "../components/Heading";
import Attribute from "../components/Attribute";
import SplitContent from "../components/SplitView";
import clinics from "../../requests/clinics";
import RemoveDialog from "./RemoveDialog";
import ChangePasswordDialog from "../components/ChangePasswordDialog";

export default {
  mixins: [],
  components: {
    Heading,
    Attribute,
    SplitContent,
    RemoveDialog,
    ChangePasswordDialog,
  },
  data: () => ({
    clinics: null,
    clinic: null,
    timeout: null,
    search: "",
    selectedItem: null,
    apiPath: "",
  }),
  computed: {},
  watch: {
    search: async function () {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(
        function () {
          this.loadItems();
        }.bind(this),
        1000
      );
    },
    selectedItem: async function () {
      if (this.selectedItem == null) {
        return;
      }
      this.clinic = await clinics.details(this.clinics[this.selectedItem].id);
    },
  },
  created: async function () {
    this.apiPath = process.env.VUE_APP_API_ROOT;
    if (!this.$route.params.id) {
      this.$router.push({ name: "clinics" });
    }
    await this.loadItems();
    for (const i in this.clinics) {
      if (this.clinics[i].id == this.$route.params.id) {
        this.selectedItem = i;
        break;
      }
    }
    if (this.selectedItem == null) {
      this.clinic = await clinics.details(this.$route.params.id);
    }
  },
  methods: {
    loadItems: async function () {
      this.clinics = (await clinics.load({ keyword: this.search })).items;
    },
  },
};
</script>

<style lang="scss" scoped></style>
